// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/opt/build/repo/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-page-template-js": () => import("/opt/build/repo/src/templates/pageTemplate.js" /* webpackChunkName: "component---src-templates-page-template-js" */),
  "component---src-templates-post-template-js": () => import("/opt/build/repo/src/templates/postTemplate.js" /* webpackChunkName: "component---src-templates-post-template-js" */),
  "component---src-templates-tags-js": () => import("/opt/build/repo/src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-index-js": () => import("/opt/build/repo/src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-insta-js": () => import("/opt/build/repo/src/pages/insta.js" /* webpackChunkName: "component---src-pages-insta-js" */),
  "component---src-pages-kontakt-js": () => import("/opt/build/repo/src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-kunden-js": () => import("/opt/build/repo/src/pages/kunden.js" /* webpackChunkName: "component---src-pages-kunden-js" */),
  "component---src-pages-landingpage-js": () => import("/opt/build/repo/src/pages/landingpage.js" /* webpackChunkName: "component---src-pages-landingpage-js" */),
  "component---src-pages-leadgenerierung-js": () => import("/opt/build/repo/src/pages/leadgenerierung.js" /* webpackChunkName: "component---src-pages-leadgenerierung-js" */),
  "component---src-pages-leistungen-js": () => import("/opt/build/repo/src/pages/leistungen.js" /* webpackChunkName: "component---src-pages-leistungen-js" */),
  "component---src-pages-pagespeed-optimierung-js": () => import("/opt/build/repo/src/pages/pagespeed-optimierung.js" /* webpackChunkName: "component---src-pages-pagespeed-optimierung-js" */),
  "component---src-pages-tags-js": () => import("/opt/build/repo/src/pages/tags.js" /* webpackChunkName: "component---src-pages-tags-js" */),
  "component---src-pages-ux-seo-audit-js": () => import("/opt/build/repo/src/pages/ux-seo-audit.js" /* webpackChunkName: "component---src-pages-ux-seo-audit-js" */),
  "component---src-pages-webentwicklung-js": () => import("/opt/build/repo/src/pages/webentwicklung.js" /* webpackChunkName: "component---src-pages-webentwicklung-js" */),
  "component---src-pages-website-konzept-js": () => import("/opt/build/repo/src/pages/website-konzept.js" /* webpackChunkName: "component---src-pages-website-konzept-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

